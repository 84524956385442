import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section className="bg-gradient-to-r from-black to-gray-900">
      <div className="container mx-auto pt-12 pb-16 px-6">
        <div className="mx-auto" style={{maxWidth: '45rem'}}>
          <h1 className="font-medium text-3xl md:text-5xl text-center leading-relaxed md:leading-tight text-gray-200 mb-2"><span className="font-bold text-white border-b-4 border-blue-500">Motion Design</span> &amp; <span className="text-white font-bold border-b-4 border-blue-500">Video Editing</span><br /> in your browser</h1>
          <p className="text-xl text-gray-400 md:text-center">After Effects is awesome, but it can be very confusing...<br />We're here to bring you powerful and easy-to-use tools so you can create animated video content, fast. ⚡️</p>
        </div>
      </div>
    </section>

    <section className="h-full bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto pb-8 pt-12 px-6">
        <h2 className="font-semibold text-3xl text-white mb-4">Our Tools</h2>
        <div className="flex flex-wrap lg:-mx-3">
          <div className="w-full lg:w-1/3 lg:px-3 mb-8">
            <div className="bg-gray-600 bg-opacity-20 p-8 rounded-lg text-white text-opacity-85 border-4 border-indigo-600">
              <a href="https://mockupclips.com" className="flex justify-center mb-7">
                <StaticImage
                  placeholder="none"
                  layout="fixed"
                  src="../images/mockups-logo.png"
                  height={32}
                  formats={["PNG", "WEBP"]}
                  alt="Mockupclips Logo"
                />
              </a>
              <div className="rounded-md flex items-center justify-center cursor-pointer relative">
                <video className="rounded-md" autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} poster={'/videos/walkthrough-ss.jpg'} >
                  <source src={'/videos/compilation.mp4'} type="video/mp4" />
                </video>
              </div>

              <p className="mt-4 text-white text-opacity-80">Place your media into beautifully shot 4K stock videos, precisely tracked by motion graphics experts</p>
              <a href="https://mockupclips.com" className="flex justify-center bg-indigo-600 transition duration-150 hover:bg-indigo-700 w-full mt-3 py-3 rounded text-white font-semibold text-lg">Create Video Mockups</a>
            </div>
          </div>

          {[1, 2].map((o, i) => (
            <div className="w-full lg:w-1/3 lg:px-3 mb-8">
              <div className={`h-full bg-gray-600 bg-opacity-10 p-8 rounded-lg text-white text-opacity-85 border-2 border-dashed border-gray-500 border-opacity-30`}>
                <div className="flex justify-center">
                  <div className="text-2xl font-medium text-gray-400 mt-1.5">Coming Soon</div>
                </div>
                <div className="flex items-center justify-center h-full w-full">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 mb-16 text-gray-500 text-opacity-70" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
            </div>
          )
          )}
        </div>
      </div>
    </section>

    <section className="h-full bg-gradient-to-br from-gray-900 to-black">
      <div className="container mx-auto pb-12 pt-12 px-6">
        <h2 className="font-semibold text-3xl text-white mb-3">About Us</h2>
        <div className="flex flex-wrap lg:-mx-4">
          <div className="w-full lg:w-1/2 xl:w-3/5 lg:px-4">
            <div className="content text-xl leading-7 text-gray-400">
              <p>Hey 👋 We're two chaps from London 🇬🇧, <a className="text-blue-400" href="https://twitter.com/motioncoder">Matt</a> and <a className="text-blue-400" href="https://twitter.com/steelcm">Chris</a>, looking to disrupt the video editing and animation sector.</p>
              <p>Matt has worked professionally in motion design and video editing for over 10 years, specialising in cutting film trailers and promotional videos including animated presentations for popular brands such as Just Eat, Sony &amp; more. 🎬</p>
              <p>The company was originally founded in 2015 by Matt who built a web app to produce automated videos powered by cloud servers running After Effects. While the app was powerful, it was difficult to scale and handle hundreds of requests at one time.</p>
              <p>Thanks to advancements in web browser technology, it's now possible to build a full video editing application using just the web, and that's what we are doing.</p>
              <p>Chris came onboard in 2019 and is the whizz behind everything technical 👨‍💻 <br />We are excited to launch more animation focused apps to make your life easier when it comes to video content creation. Watch this space 🚀</p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 xl:w-2/5 lg:px-4">
            <div className="flex justify-center flex-wrap mb-6">
              <img className="inline-block h-40 w-40 sm:h-64 sm:w-64 border-8 border-blue-500 shadow-xl rounded-full object-cover" src={`/images/matt.jpg`} alt="Matt" />
              <img className="inline-block h-40 w-40 sm:h-64 sm:w-64 border-8 border-white shadow-xl rounded-full object-cover -ml-12" src={`/images/chris.jpg`} alt="Matt" />
            </div>
          </div>
        </div>

        <a href="mailto:hello@editsquare.com" className="inline-flex items-center justify-center leading-tight bg-gray-400 bg-opacity-10 border-2 border-gray-400 border-opacity-10 transition duration-150 hover:bg-blue-600 mt-3 py-3 pl-6 pr-8 rounded text-white font-semibold text-lg">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
          Contact Us</a>
      </div>
    </section>

  </Layout>
)

export default IndexPage
